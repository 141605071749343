import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Popup from 'reactjs-popup';

import router from '../index';
import { UploadItem } from './Upload';
import Action from './Action';
import Content from './Content';
import global from './Global';
import Preview from './Preview';
import { encrypt, decrypt, noAccess, cleanPath } from '../js/functions';
import { copyMove, selection, changePreviewItem } from '../js/helper';

//############################################################################# Animation

const effect = {
    init: {
        x: -20,
        opacity: 0,
    },
    in: {
        x: 0,
        opacity: 1,
        transition: {
            x: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
            opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
        },
    },
    out: {
        x: 20,
        opacity: 0,
        transition: {
            x: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
            opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
        },
    },
};

//#############################################################################

class OutletPage extends Component {
    constructor() {
        super();
        this._action = React.createRef();
    }

    localstate = observable({
        uploadlistExpanded: false,
    });

    open(path = '/', type = 'folder', clicks) {
        if (clicks === 2) {
            if (type === 'folder') {
                router.navigate(encrypt(`${cleanPath(path)}/`));
            } else {
                changePreviewItem({ name: path });
            }
            selection([], true);
        }
    }

    render() {
        const { user, loading, uploads, selected, sharedFolder, preview, screen, animation, error404 } = global;
        const { uploadlistExpanded } = this.localstate;

        const pathList = decrypt(this.props.params['*'])
            .split('/')
            .filter(x => x !== '' && x !== '/');
        const copymoveItems = [...selected.copymove.folders, ...selected.copymove.files];
        const uploadingItem = uploads.find((x, i) => x.status === 'uploading' || i === uploads.length - 1) || null;
        const selectedItems = [...selected.folders, ...selected.files];
        const hiddenPathList = sharedFolder ? sharedFolder.hiddenPath.split('/') : [];

        const headerPathList = sharedFolder
            ? ['shared', ...pathList.filter(x => !hiddenPathList.includes(x))]
            : pathList[0] === 'shared' || error404
            ? []
            : pathList;

        return (
            <>
                <div id="header">
                    {headerPathList.length > 0 ? (
                        <div id="path">
                            <div className="buttons">
                                <button
                                    className="icon"
                                    onClick={() => {
                                        let folderPath = headerPathList.slice(0, -1).join('/');
                                        if (sharedFolder && folderPath !== 'shared')
                                            folderPath = folderPath.replace('shared', sharedFolder.hiddenPath);
                                        router.navigate(folderPath === 'shared' ? '/' : encrypt(`${folderPath}/`));
                                    }}
                                >
                                    <i className="fal fa-arrow-left"></i>
                                </button>
                                <button className="icon" onClick={() => router.navigate('/')}>
                                    <i className="fal fa-home"></i>
                                </button>
                            </div>
                            {
                                <AnimatePresence>
                                    {headerPathList.map((x, i) => {
                                        return (
                                            <motion.div
                                                key={i}
                                                initial={effect.init}
                                                animate={effect.in}
                                                exit={effect.out}
                                            >
                                                {i > 0 && <i className="fal fa-chevron-right"></i>}
                                                <p
                                                    onClick={() => {
                                                        let folderPath = headerPathList
                                                            .filter((y, i) => i <= headerPathList.indexOf(x))
                                                            .join('/');
                                                        if (sharedFolder && folderPath !== 'shared')
                                                            folderPath = folderPath.replace(
                                                                'shared',
                                                                sharedFolder.hiddenPath
                                                            );
                                                        router.navigate(
                                                            folderPath === 'shared' ? '/' : encrypt(`${folderPath}/`)
                                                        );
                                                    }}
                                                >
                                                    {x === 'shared' && sharedFolder ? sharedFolder.owner : x}
                                                </p>
                                            </motion.div>
                                        );
                                    })}
                                </AnimatePresence>
                            }
                        </div>
                    ) : (
                        <AnimatePresence>
                            <motion.h1 initial={effect.init} animate={effect.in} exit={effect.out}>
                                <i className="fas fa-cloud"></i>My Data Safe
                            </motion.h1>
                        </AnimatePresence>
                    )}
                </div>

                <Action ref={this._action} />

                <Content open={this.open} action={this._action} />

                <AnimatePresence>
                    {screen.width <= 820 && uploads.length > 0 && (
                        <motion.div
                            id="mobile-uploads"
                            className="item"
                            initial={animation.slide.init}
                            animate={animation.slide.in}
                            exit={animation.slide.out}
                        >
                            <AnimatePresence>
                                {uploadlistExpanded && (
                                    <motion.div
                                        id="upload-list"
                                        initial={animation.slide.init}
                                        animate={animation.slide.in}
                                        exit={animation.slide.out}
                                    >
                                        <h1>Uploads</h1>
                                        <div className="flex-col">
                                            <AnimatePresence>
                                                {uploads.map((file, i) => (
                                                    <UploadItem key={i} file={file} />
                                                ))}
                                            </AnimatePresence>
                                        </div>
                                        <button
                                            className="icon"
                                            title="Uploads leeren"
                                            onClick={() => {
                                                global.uploads = [];
                                                this.localstate.uploadlistExpanded = false;
                                            }}
                                        >
                                            <i className="fal fa-trash"></i>
                                        </button>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <div
                                id="upload-current"
                                onClick={() => (this.localstate.uploadlistExpanded = !uploadlistExpanded)}
                            >
                                <UploadItem file={uploadingItem} noexit={true} />
                                <i className={`fal fa-chevron-up ${uploadlistExpanded ? 'expanded' : ''}`}></i>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {screen.width <= 820 && selectedItems.length === 0 && (
                        <motion.div
                            id="taskbar"
                            initial={animation.slide.init}
                            animate={animation.slide.in}
                            exit={animation.slide.out}
                        >
                            <Popup
                                trigger={
                                    <button id="btn-taskbar-create" className="icon-text">
                                        <i className="fal fa-plus-circle"></i>
                                        <small>Erstellen</small>
                                    </button>
                                }
                                position="top left"
                                on="hover"
                            >
                                {!user && (
                                    <button className="inmenu" onClick={() => (global.modal = 'register')}>
                                        <i className="fal fa-user-plus"></i>
                                        <span>Neuer Nutzer</span>
                                    </button>
                                )}
                                <button
                                    className="inmenu"
                                    disabled={!user}
                                    onClick={() => {
                                        if (!noAccess('createFolder')) global.modal = 'createFolder';
                                    }}
                                >
                                    <i className="fal fa-folder-plus"></i>
                                    <span>Neuer Ordner</span>
                                </button>
                                <button
                                    className="inmenu"
                                    disabled={!user}
                                    onClick={() => {
                                        if (!noAccess('createNote')) global.modal = 'createNote';
                                    }}
                                >
                                    <i className="fal fa-note"></i>
                                    <span>Notiz</span>
                                </button>
                            </Popup>
                            <button
                                className="icon-text"
                                onClick={() => {
                                    if (!noAccess('upload')) document.querySelector('#input-upload').click();
                                }}
                            >
                                <i className="fal fa-cloud-upload"></i>
                                <small>Hochladen</small>
                            </button>
                            {user ? (
                                <Popup
                                    trigger={
                                        <button className="icon-text">
                                            <i className="fal fa-user-circle"></i>
                                            <small>{user.name}</small>
                                        </button>
                                    }
                                    position="top right"
                                    on="hover"
                                >
                                    <button
                                        className="inmenu"
                                        disabled={loading}
                                        onClick={() => (global.modal = 'logout')}
                                    >
                                        <i className="fal fa-arrow-right-from-bracket"></i>
                                        <span>Abmelden</span>
                                    </button>
                                    <button
                                        className="inmenu"
                                        disabled={loading}
                                        onClick={() => (global.modal = 'theme')}
                                    >
                                        <i className="fal fa-palette"></i>
                                        <span>Designfarbe</span>
                                    </button>
                                    <button
                                        className="inmenu"
                                        disabled={loading}
                                        onClick={() => (global.modal = 'changePassword')}
                                    >
                                        <i className="fal fa-key"></i>
                                        <span>Passwort ändern</span>
                                    </button>
                                    <button
                                        className="inmenu"
                                        // disabled={loading}
                                        disabled
                                        onClick={() => (global.modal = 'deleteUser')}
                                    >
                                        <i className="fal fa-trash"></i>
                                        <span>Alles löschen</span>
                                    </button>
                                </Popup>
                            ) : (
                                <button className="icon-text" onClick={() => (global.modal = 'login')}>
                                    <i className="fal fa-user-circle"></i>
                                    <small>Anmelden</small>
                                </button>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {copymoveItems.length > 0 && (
                        <motion.div
                            id="copymove"
                            initial={animation.fade.init}
                            animate={animation.fade.in}
                            exit={animation.fade.out}
                        >
                            <p>
                                {copymoveItems.length} {copymoveItems.length === 1 ? 'Element' : 'Elemente'}
                            </p>
                            <button className="primary" disabled={loading} onClick={() => copyMove()}>
                                {selected.copymove.action === 'copy' ? (
                                    <>
                                        <i className="fal fa-copy"></i>
                                        <span>Hier hin kopieren</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="fal fa-file-export"></i>
                                        <span>Hier her verschieben</span>
                                    </>
                                )}
                            </button>
                            <button
                                className="icon"
                                title="Alle abwählen"
                                onClick={() => {
                                    global.selected.copymove.folders = [];
                                    global.selected.copymove.files = [];
                                }}
                            >
                                <i className="fal fa-xmark"></i>
                            </button>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>{preview.item && <Preview />}</AnimatePresence>
            </>
        );
    }
}

OutletPage = observer(OutletPage);

const ClassWrapper = () => {
    const params = useParams();
    return <OutletPage params={params} />;
};

export default ClassWrapper;
